import { t } from '@lingui/macro';
import en from './FlagImgs/en.png';
import el from './FlagImgs/el.png';
import sv from './FlagImgs/sv.png';
import is from './FlagImgs/is.png';
import it from './FlagImgs/it.png';
import de from './FlagImgs/de.png';
import fr from './FlagImgs/fr.png';
import da from './FlagImgs/da.png';
import nl from './FlagImgs/nl.png';
import bg from './FlagImgs/bg.png';
import hr from './FlagImgs/hr.png';
import cs from './FlagImgs/cs.png';
import hu from './FlagImgs/hu.png';
import lt from './FlagImgs/lt.png';
import pl from './FlagImgs/pl.png';
import pt from './FlagImgs/pt.png';
import ro from './FlagImgs/ro.png';
import sk from './FlagImgs/sk.png';
import lv from './FlagImgs/lv.png';
import mt from './FlagImgs/mt.png';
import no from './FlagImgs/no.png';
import sl from './FlagImgs/sl.png';
import es from './FlagImgs/es.png';
import et from './FlagImgs/et.png';

export interface ILanguagesAvailable {
    id: string;
    name: string;
    isTranslated: boolean;
    icon?: string;
}

export const getAvailableLanguages = () => {
    // change isTranslated to true for the languages when they are translated
    const languages: ILanguagesAvailable[] = [
        { id: 'en', name: t`English`, isTranslated: true, icon: en },
        { id: 'de', name: t`German`, isTranslated: true, icon: de },
        { id: 'fr', name: t`French`, isTranslated: true, icon: fr },
        { id: 'nl', name: t`Dutch`, isTranslated: true, icon: nl },
        { id: 'bg', name: t`Bulgarian`, isTranslated: true, icon: bg },
        { id: 'hr', name: t`Croatian`, isTranslated: true, icon: hr },
        { id: 'el', name: t`Greek`, isTranslated: true, icon: el },
        { id: 'cs', name: t`Czech`, isTranslated: true, icon: cs },
        { id: 'da', name: t`Danish`, isTranslated: true, icon: da },
        { id: 'et', name: t`Estonian`, isTranslated: true, icon: et },
        { id: 'hu', name: t`Hungarian`, isTranslated: true, icon: hu },
        { id: 'is', name: t`Icelandic`, isTranslated: true, icon: is },
        { id: 'it', name: t`Italian`, isTranslated: true, icon: it },
        { id: 'lv', name: t`Latvian`, isTranslated: true, icon: lv },
        { id: 'lt', name: t`Lithuanian`, isTranslated: true, icon: lt },
        { id: 'mt', name: t`Maltese`, isTranslated: true, icon: mt },
        { id: 'no', name: t`Norwegian`, isTranslated: true, icon: no },
        { id: 'pl', name: t`Polish`, isTranslated: true, icon: pl },
        { id: 'pt', name: t`Portuguese`, isTranslated: true, icon: pt },
        { id: 'ro', name: t`Romanian`, isTranslated: true, icon: ro },
        { id: 'sk', name: t`Slovakian`, isTranslated: true, icon: sk },
        { id: 'sl', name: t`Slovenian`, isTranslated: true, icon: sl },
        { id: 'es', name: t`Spanish`, isTranslated: true, icon: es },
        { id: 'sv', name: t`Swedish`, isTranslated: true, icon: sv },
        { id: 'pseudo-en', name: t`Pseudo-English`, isTranslated: true },
    ];
    const translatedLanguages = languages.filter(
        (language) =>
            language.isTranslated &&
            !(
                (
                    process.env.NODE_ENV === 'production' &&
                    language.id === 'pseudo-en' &&
                    !window.location.hostname.includes('preprod')
                ) // preprod comes under production but we want to show pseudo-en in preprod
            ),
    );
    return translatedLanguages;
};
